import Head from "next/head";
import dynamic from "next/dynamic";

const BusTicketOrder = dynamic(() => import("sections/home/bus-ticket-order"), {
  ssr: false,
});

export const metadata = {
  title: "Коретабус: Замовлення Автобусного Квитка",
  description:
    "Відкрийте нові горизонти з Коретабус! Замовте квиток на автобус та відправляйтеся у захоплюючі подорожі з комфортом та стилем. Обирайте з нашого розмаїття маршрутів та насолоджуйтесь кожною миттю вашої подорожі. Приєднуйтесь до нас сьогодні та відчуйте справжню свободу мандрівництва!",
};

const Page = () => (
  <>
    <Head>
      <title>Коретабус: Замовлення Автобусного Квитка</title>
      <meta
        name="description"
        content="Відкрийте нові горизонти з Коретабус! Замовте квиток на автобус та відправляйтеся у
          захоплюючі подорожі з комфортом та стилем. Обирайте з нашого розмаїття маршрутів та
          насолоджуйтесь кожною миттю вашої подорожі. Приєднуйтесь до нас сьогодні та відчуйте
          справжню свободу мандрівництва!"
      />
    </Head>
    <BusTicketOrder />
  </>
);

Page.getLayout = (page) => <>{page}</>;

export default Page;
